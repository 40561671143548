


























































import { Component, Vue } from "vue-property-decorator";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import { ValidationObserver } from "vee-validate";
import AccountDataContext from "@/dataContexts/AccountDataContext";
import Notification from "@/common/Notification";
import { CaptchaComponent } from "keiryo";
import AccountErrors from "@/common/AccountErrors";
import { InputComponent } from "keiryo";
import Settings from "@/Settings";
import PasswordNotice from "@/components/global/PasswordNotice.vue";

@Component({
    components: {
        InputComponent,
        CaptchaComponent,
        PasswordNotice
    },
})
export default class RegisterView extends Vue {
    private accountDataContext: AccountDataContext = new AccountDataContext();

    private loading = false;
    private confirmEmail = false;

    private username = "";
    private email = "";
    private password = "";
    private confirmPassword = "";
    private captchaResponse = "";

    private siteKey = Settings.HCaptcha.SiteKey;

    private async onSubmit() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        if (await observer.validate() && this.captchaResponse) {
            this.loading = true;

            this.accountDataContext.register(this.username, this.email, this.password, this.captchaResponse).then(x => {
                if (x.successfully) {
                    this.confirmEmail = true;
                } else {
                    if (x.data) {
                        let errorContentKey: string|undefined;

                        switch (x.data) {
                            case AccountErrors.DuplicateUserName:
                                errorContentKey = "view.register.duplicateUserName";
                                break;

                            case AccountErrors.FaultyEmail:
                                errorContentKey = "view.register.faultyEmail";
                                break;

                            case AccountErrors.InvalidCaptcha:
                                errorContentKey = "view.register.invalidCaptcha";
                                break;

                            default:
                                break;
                        }

                        if (errorContentKey) {
                            Notification.addError(TranslationUtils.translate(errorContentKey), false).show();
                        }
                    }
                }
            }).finally(() => this.loading = false);
        }
    }
}
